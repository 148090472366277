import get from 'lodash/get';
import set from 'lodash/set';
import pickBy from 'lodash/pickBy';
import { Config, EarlyTag, Tag } from './index';

export class TaggingClient {
  // --------------- PUBLIC --------------------
  TAG_PERMANENT_KEYS: Array<string> | undefined;
  LOG_DEBUG: boolean | undefined;
  TARGET_COMPLIANT = false;
  earlyTags: Array<EarlyTag> = [];

  // --------------- PRIVATE ---------------
  private _previousView = '';
  private _TARGET_EVENTS = {
    viewStart: 'at-view-start',
    viewEnd: 'at-view-end',
    creationStart: 'aa-creation-start',
    creationEnd: 'aa-creation-end',
  };
  /**
   * Initialise le DataLayer avec les infos permanentes
   * @param initTags
   * @param config
   */
  public async initTagging(initTags: Record<string, unknown>, config?: Config): Promise<void> {
    (<any>window)['wa_gfr'] = initTags;
    this._setConfiguration(config);
    await this.isTaggingReady().then((taggingReady) => {
      if (taggingReady) {
        this._commitEarlyTag();
      }
    });
  }

  /**
   * Indique si le tagging est pret a etre utilisé
   * @returns {boolean}
   */
  public async isTaggingReady(attempts = 5, interval = 1000): Promise<boolean> {
    try {
      await this.checkLib(attempts, interval);
      return true;
    } catch (error) {
      if (this.LOG_DEBUG) {
        console.error(error.message);
      }
      return false;
    }
  }

  private checkLib(maxAttempts: number, interval: number): Promise<void> {
    let attempts = 0;

    return new Promise((resolve, reject) => {
      const checkExistence = () => {
        // Vérifie si la lib existe dans le contexte window
        if (
          (<any>window)._satellite &&
          (<any>window)._satellite.track &&
          (<any>window)._satellite.track.toString().length > 12 &&
          typeof this.TAG_PERMANENT_KEYS != 'undefined'
        ) {
          resolve();
        } else {
          attempts++;
          if (attempts < maxAttempts) {
            // Si la lib n'est pas encore dispo, on réessaie après un certain intervalle
            setTimeout(checkExistence, interval);
          } else {
            // Si on a dépassé le nombre d'essais, on rejette la promesse, pas de bras, pas de chocolats, pas de pierre pas de palais !
            reject(new Error(`La lib n'a pas été trouvée après ${maxAttempts} tentatives.`));
          }
        }
      };
      // Lance la première vérification
      checkExistence();
    });
  }

  /**
   * modifie le datalayer et commit le tag
   * @param tags
   * @param tagEvent
   */
  public async updateAndCommitTag(tags: Array<Tag>, tagEvent: string): Promise<void> {
    return await this.isTaggingReady().then((taggingReady) => {
      if (taggingReady) {
        this._commitEarlyTag();
        this._updateAndCommit(tags, tagEvent);
      } else {
        console.warn('tagging not ready !!');
        this.earlyTags.push({ tags, tagEvent });
      }
    });
  }

  // --------------- PRIVATE --------------------
  private _updateAndCommit(tags: Array<Tag>, tagEvent: string) {
    if (this.LOG_DEBUG) {
      console.log('update : ' + tagEvent, tags);
    }
    this._updateDataLayer(tags);
    if (tagEvent !== 'noTrack' && (!this.TARGET_COMPLIANT || (this.TARGET_COMPLIANT && tagEvent !== 'esc-track-nav'))) {
      this._commitTag(tagEvent);
    } else if (this.TARGET_COMPLIANT && tagEvent === 'esc-track-nav') {
      this._triggerTarget(tags);
    }
  }

  private _commitEarlyTag(): void {
    if (this.earlyTags.length > 0) {
      this.earlyTags.forEach((objectTag) => this._updateAndCommit(objectTag.tags, objectTag.tagEvent));
      this.earlyTags = [];
    }
  }

  /**
   * modifie le datalayer avec X demande(s)
   */
  private _updateDataLayer(tags: Array<Tag>) {
    tags.forEach((tag: Tag) => this._updateOneDL(tag));
  }

  /**
   * modifie le datalayer avec 1 demande
   */
  private _updateOneDL(tag: Tag) {
    const layer = (<any>window)['wa_gfr'];
    if (tag.add) {
      let value = get(layer, tag.to);
      if (typeof value === 'string') {
        value += tag.add;
        (<any>window)['wa_gfr'] = set(layer, tag.to, value);
      } else {
        console.error(`Impossible d'ajouter ${tag.add} au tag courant`);
      }
    } else if (tag.set) {
      (<any>window)['wa_gfr'] = set(layer, tag.to, tag.set);
    }
  }

  /**
   * envoie un tag avec les info du datalayer
   * @param tagEvent
   */
  private _commitTag(tagEvent: string) {
    if (this.LOG_DEBUG) {
      console.log('wa_gfr : ', (<any>window)['wa_gfr']);
    }
    (<any>window)._satellite.track(tagEvent);
    this._cleanLayer();
  }

  /**
   * declenche un event trigger pour adobe target
   */
  private _triggerTarget(tags: Array<Tag>) {
    if (!(window.navigator.userAgent.toLowerCase().indexOf('trident') > -1)) {
      tags.forEach((request) => {
        //TODO: configurable sur le to ?
        if (request.set && request.set != this._previousView && request.to === 'contenu.fil_ariane') {
          this._dispatchViewStart(request.set);
          this._dispatchViewEnd(request.set);
          this._dispatchCreationStart();
          this._dispatchCreationEnd();
          this._previousView = request.set;
        }
      });
    } else {
      if (this.LOG_DEBUG) {
        console.log('Browser is detected as IE');
      }
    }
  }

  private _dispatchViewStart(viewName: string) {
    this._dispatchTargetEvent(this._TARGET_EVENTS.viewStart, viewName);
  }

  private _dispatchViewEnd(viewName: string) {
    this._dispatchTargetEvent(this._TARGET_EVENTS.viewEnd, viewName);
  }

  private _dispatchCreationStart() {
    this._dispatchTargetEvent(this._TARGET_EVENTS.creationStart);
  }

  private _dispatchCreationEnd() {
    this._dispatchTargetEvent(this._TARGET_EVENTS.creationEnd);
  }

  private _dispatchTargetEvent(eventName: string, viewName?: string) {
    document.body.dispatchEvent(
      new CustomEvent(eventName, {
        detail: {
          viewName: viewName,
        },
      })
    );
    if (this.LOG_DEBUG) {
      console.log('Adobe : target dispatch event : ', {
        eventName: eventName,
        viewName: viewName,
        time: new Date(),
      });
    }
  }

  private _cleanLayer(): void {
    (<any>window)['wa_gfr'] = pickBy(
      (<any>window)['wa_gfr'],
      (value, key) => this.TAG_PERMANENT_KEYS && this.TAG_PERMANENT_KEYS.includes(key)
    );
  }

  private _setConfiguration(config?: Config): void {
    this.TAG_PERMANENT_KEYS = config && config.permanentKey ? config.permanentKey : ['contenu', 'CRM', 'contact'];
    this.LOG_DEBUG = !!config && !!config.logDebug;
    this.TARGET_COMPLIANT = !!config && !!config.targetCompliant;
  }
}

const taggingClient = new TaggingClient();
export default taggingClient;
