"use strict";
import {TaggingClient} from 'tagging-client';
import * as Bowser from "bowser";
import {init, updateAndCommitTag} from './init'
import {nextStep, nextStepWithFilling, previousStep} from './steps/steps';

export {updateAndCommitTag}

declare const window: MyWindow;

// init input validation and tooltip, datePicker if needed
export const device= Bowser.parse(window.navigator.userAgent);
export const taggingClient = new TaggingClient();
window.init = init;

// provide method we need for tagging
window.updateAndCommitTag=updateAndCommitTag;

// provide methods we need for steps
window.nextStepWithFilling=nextStepWithFilling;
window.nextStep=nextStep;
window.previousStep=previousStep;

window.device = device;