import taggingClient from './tagging-client';
import { TaggingClient } from './tagging-client';
export { TaggingClient };

export const initTagging = taggingClient.initTagging;
export const updateAndCommitTag = taggingClient.updateAndCommitTag;
export const isTaggingReady = taggingClient.isTaggingReady;
// interfaces

export interface Config {
  permanentKey?: Array<string>;
  logDebug?: boolean;
  targetCompliant?: boolean;
}

export interface Tag {
  set?: string;
  add?: string;
  to: string;
}

export interface EarlyTag {
  tags: Array<Tag>;
  tagEvent: string;
}
